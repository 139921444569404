import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
//import NewsletterCompact from '@widgets/NewsletterCompact'
import NewsletterExpanded from '@widgets/NewsletterExpanded'

export default () => {

  return (
    <>
      <Seo title='Subscribe' />
      <Divider />
      <Stack effect='fadeInDown'>
        <Main>
          <PageTitle
            header=''
            subheader=''
          />
          <NewsletterExpanded />
        </Main>
      </Stack>
    </>
  )
}
